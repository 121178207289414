class MainMenu {
    /**
     * @param element {HTMLElement|Element}
     */
    constructor(element) {
        /**
         * @type {HTMLElement|Element}
         */
        this.root = element

        this.breakPointMd = matchMedia('(min-width: 768px)')

        this.initializeElements()
        this.initializeEvents()

        // Enable transitions first after all resources are loaded, to avoid closing animation at start
        window.addEventListener('load', () => {
            this.root.classList.add('is-animated')
        })
    }

    initializeElements() {
        /**
         * @type {HTMLElement|Element}
         */
        this.toggle = this.root.querySelector('.js-main-menu-toggle')
        /**
         * @type {NodeListOf<HTMLElement|Element>|HTMLElement[]|Element[]|NodeList<HTMLElement|Element>}
         */
        this.menuItems = this.root.querySelectorAll('.js-menu-item')
        this.subBackButtons = this.root.querySelectorAll('.js-sub-menu-back')
        this.subToggles = this.root.querySelectorAll('.js-sub-menu-toggle')
    }

    initializeEvents() {
        this.toggle.addEventListener('click', () => {
            if (this.isOpen()) {
                this.close()
            } else {
                this.open()
            }
        })

        const openMenuItem = item => {
            if (this.isMobile()) {
                this.root.classList.add('is-sub-open')
            }
            item.classList.add('is-open')
        }
        let menuItemCloseTimeout = null

        this.menuItems.forEach(item => {
            if (item.classList.contains('has-children')) {
                item.addEventListener('click', ev => {
                    if (!item.classList.contains('is-open')) {
                        ev.preventDefault()
                        openMenuItem(item)
                    }
                })
            }

            item.addEventListener('mouseover', () => {
                if (!this.isMobile()) {
                    clearTimeout(menuItemCloseTimeout)
                    this.menuItems.forEach(item => {
                        item.classList.remove('is-open')
                    })
                    openMenuItem(item)
                }
            })
            item.addEventListener('mouseleave', () => {
                if (!this.isMobile()) {
                    menuItemCloseTimeout = setTimeout(() => {
                        item.classList.remove('is-open')
                    }, 500)
                }
            })
        })

        this.subBackButtons.forEach(button => {
            button.addEventListener('click', ev => {
                ev.stopPropagation()
                if (button.parentElement.classList.contains('is-open')) {
                    this.root.classList.remove('is-sub-open')
                    this.root.addEventListener('transitionend', () => {
                        button.parentElement.classList.remove('is-open')
                    }, { once: true })
                }
            })
        })

        this.subToggles.forEach(toggle => {
            toggle.addEventListener('click', ev => {
                ev.stopPropagation()
                toggle.parentElement.classList.toggle('is-open')
            })
        })

        // Avoid clicks from the menu bubble up and close the menu
        this.root.addEventListener('click', ev => {
            ev.stopPropagation()
        })

        // Close open menu, when click anywhere on the page, except the menu (see above)
        document.addEventListener('click', () => {
            this.menuItems.forEach(item => {
                item.classList.remove('is-open')
            })
        })
    }

    open() {
        this.root.classList.add('is-open')
        document.body.classList.add('is-menu-open')
    }

    close() {
        this.root.classList.add('is-closing')
        this.root.addEventListener('transitionend', () => {
            this.menuItems.forEach(item => {
                item.classList.remove('is-open')
            })
            this.root.classList.remove('is-sub-open')
            this.root.classList.remove('is-closing')
            document.body.classList.remove('is-menu-open')
        }, { once: true })
        this.root.classList.remove('is-open')
    }

    isOpen() {
        return this.root.classList.contains('is-open')
    }

    isMobile() {
        return !this.breakPointMd.matches
    }
}

export { MainMenu }
