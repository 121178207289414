import { MainMenu } from './components/3-molecules/main-menu/main-menu.js'
import { FileUpload } from './components/2-atoms/form/file-upload.js'
import { PageTree } from './components/3-molecules/page-tree/page-tree.js'
import { Accordion } from './components/3-molecules/accordion/accordion.js'

document.addEventListener('DOMContentLoaded', () => {
    const mainMenuElement = document.querySelector('.js-main-menu')
    if (mainMenuElement) {
        // eslint-disable-next-line no-new
        new MainMenu(mainMenuElement)
    }

    const fileUploads = document.querySelectorAll('.js-file-upload')
    if (fileUploads) {
        fileUploads.forEach(fileUpload => {
            // eslint-disable-next-line no-new
            new FileUpload(fileUpload)
        })
    }

    const pageTrees = document.querySelectorAll('.js-page-tree')
    if (pageTrees) {
        pageTrees.forEach(pageTree => {
            // eslint-disable-next-line no-new
            new PageTree(pageTree)
        })
    }

    const accordionElements = document.querySelectorAll('.js-accordion')
    if (accordionElements) {
        accordionElements.forEach(accordionElement => {
            // eslint-disable-next-line no-new
            new Accordion(accordionElement)
        })
    }
})
