class PageTree {
    /**
     * @param rootElement {Element | HTMLElement}
     */
    constructor(rootElement) {
        this.root = rootElement

        this.initializeElements()
        this.initializeEvents()
    }

    initializeElements() {
        this.selectAllButton = this.root.querySelector('.js-page-tree-select-all')
        this.deselectAllButton = this.root.querySelector('.js-page-tree-deselect-all')
        this.checkboxes = this.root.querySelectorAll('.checkbox')
        this.subToggles = this.root.querySelectorAll('.js-sub-toggle')
    }

    initializeEvents() {
        this.selectAllButton.addEventListener('click', () => {
            this.checkboxes.forEach(checkbox => {
                checkbox.checked = true
            })
        })
        this.deselectAllButton.addEventListener('click', () => {
            this.checkboxes.forEach(checkbox => {
                checkbox.checked = false
            })
        })

        this.checkboxes.forEach(checkbox => {
            checkbox.checkbox = new Checkbox(checkbox)
            const parentItem = checkbox.closest('.page-tree__item').closest('.page-tree__list').closest('.page-tree__item')
            if (parentItem) {
                const parentCheckboxElement = parentItem.querySelector(':scope > .form__field > .checkbox')
                parentCheckboxElement.checkbox.addChild(checkbox.checkbox)
            }
        })

        this.subToggles.forEach(subToggle => {
            subToggle.addEventListener('click', () => {
                subToggle.closest('.page-tree__item').classList.toggle('is-open')
            })
        })
    }
}

class Checkbox {
    /**
     * @param rootElement {Element|HTMLElement}
     */
    constructor(rootElement) {
        /**
         * @type {Element|HTMLElement}
         */
        this.root = rootElement

        /**
         *
         * @type {Checkbox|null}
         */
        this.parent = null

        /**
         * @type {Checkbox[]}
         */
        this.childCheckboxes = []

        this.eventTarget = new EventTarget()

        this.initializeElements()
        this.initializeEvents()
    }

    initializeElements() {
        this.input = this.root.querySelector('input[type="checkbox"]')
    }

    initializeEvents() {
        this.input.addEventListener('change', () => {
            this.childCheckboxes.forEach(child => {
                child.checked = this.checked
            })
            this.dispatchEvent(new Event('change'))
        })
    }

    addEventListener(type, listener, options = true) {
        this.eventTarget.addEventListener(type, listener, options)
    }

    removeEventListener(type, listener, options = true) {
        this.eventTarget.removeEventListener(type, listener, options)
    }

    dispatchEvent(event) {
        this.eventTarget.dispatchEvent(event)
    }

    get checked() {
        return this.input.checked
    }

    /**
     * This setter is used to communicate a change down to the children
     *
     * @param checked {boolean}
     */
    set checked(checked) {
        this.input.checked = checked

        this.childCheckboxes.forEach(child => {
            child.checked = this.checked
        })
    }

    /**
     * @param checkbox {Checkbox}
     */
    addChild(checkbox) {
        this.childCheckboxes.push(checkbox)
        checkbox.setParent(this)

        /*
         * This event listener is used to update the `checked` state of the `input`, when a child changes
         */
        checkbox.addEventListener('change', () => {
            let checked = false
            this.childCheckboxes.forEach(child => {
                checked |= child.checked
            })
            if (this.input.checked !== checked) {
                this.input.checked = checked
                this.dispatchEvent(new Event('change'))
            }
        })
    }

    /**
     * @param checkbox {Checkbox}
     */
    setParent(checkbox) {
        this.parent = checkbox
    }
}

export { PageTree }
