class FileUpload {
    /**
     * @param element {Element|HTMLElement}
     */
    constructor(element) {
        /**
         * @type {Element|HTMLElement}
         */
        this.root = element

        this.initializeElements()
        this.initializeEvents()
    }

    initializeElements() {
        /**
         * @type {Element|HTMLElement|HTMLInputElement}
         */
        this.control = this.root.querySelector('input[type="file"]')
        /**
         * @type {Element|HTMLElement}
         */
        this.fileName = this.root.querySelector('.js-file-name')
        this.fileName.dataset.originalFileName = this.fileName.innerHTML

        this.dragAndDropIndicator = this.root.querySelector('.js-drop-indicator')
    }

    initializeEvents() {
        this.control.addEventListener('change', () => {
            this.updateFileNames()
        })

        if (this.dragAndDropIndicator) {
            for (const eventName of ['dragover', 'dragenter']) {
                this.root.addEventListener(eventName, this.startDragAndDrop.bind(this))
            }

            for (const eventName of ['dragleave', 'dragend', 'drop']) {
                this.root.addEventListener(eventName, this.endDragAndDrop.bind(this))
            }

            this.root.addEventListener('drop', ev => {
                ev.preventDefault()
                this.control.files = ev.dataTransfer.files
                this.updateFileNames()
            })
        }
    }

    startDragAndDrop(ev) {
        ev.preventDefault()
        this.root.classList.add('is-drop-enabled')
    }

    endDragAndDrop(ev) {
        ev.preventDefault()
        this.root.classList.remove('is-drop-enabled')
    }

    updateFileNames() {
        const fileNames = []
        for (const file of this.control.files) {
            fileNames.push(file.name)
        }
        if (fileNames.length > 0) {
            this.fileName.innerHTML = fileNames.join(', ')
            this.fileName.setAttribute('title', fileNames.join(', '))
        } else {
            this.fileName.innerHTML = this.fileName.dataset.originalFileName
            this.fileName.removeAttribute('title')
        }
    }
}

export { FileUpload }
