class Accordion {
    /**
     * @param rootElement {Element|HTMLElement}
     */
    constructor(rootElement) {
        this.root = rootElement

        this.initializeElements()
        this.initializeEvents()
    }

    initializeElements() {
        this.header = this.root.querySelector('.js-accordion-header')
        this.content = this.root.querySelector('.js-accordion-content')
    }

    initializeEvents() {
        this.header.addEventListener('click', () => {
            this.open = !this.open
        })
    }

    calculateTargetHeight() {
        this.root.classList.add('is-calculating')
        const height = window.getComputedStyle(this.content).getPropertyValue('height')
        this.root.classList.remove('is-calculating')

        return height
    }

    get open() {
        return this.root.classList.contains('is-open')
    }

    set open(open) {
        if (open && !this.open) {
            // Is closed and should open
            this.root.classList.add('is-open')
            this.root.classList.remove('is-closing')
            this.content.style.height = this.calculateTargetHeight()
        } else if (!open && this.open) {
            // Is open and should close
            this.root.classList.add('is-closing')
            this.root.addEventListener('transitionend', () => {
                this.root.classList.remove('is-closing')
            }, { once: true })
            this.root.classList.remove('is-open')
            this.content.style.height = ''
        }
    }
}

export { Accordion }
